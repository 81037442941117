import React, { useEffect, useRef, useState } from 'react'
import './App.css'
import { parseTimeLines } from './utils/parseTimeLines'
import { parseSoftLineBreaks } from './utils/parseSoftLineBreaks'
import { useMeasure } from './utils/useMeasure'
import { useLocalStorage } from './utils/useStorage'
import { minutesToHours, minutesToTime } from './utils/timeUtils'
import { sum, roundUpToClosest } from './utils/numberUtils'
import { formatTimesheet } from './utils/formatTimesheet'

export default function App() {
  const [val, setVal] = useLocalStorage('rawText', '')
  const [copied, setCopied] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const copyTimeoutRef = useRef(0)
  const [charactersPerLine, setCharactersPerLine] = useState(0)
  const characterRef = useRef<HTMLSpanElement | null>(null)
  const [resizeObserverRef, resizeRect] = useMeasure<HTMLDivElement>()
  const [lineHeight, setLineHeight] = useState(24)
  const multiple = 5
  const [now, setNow] = useState(new Date())

  // re-render every minute
  useEffect(() => {
    const timeout = setTimeout(() => setNow(new Date()), (61 - now.getSeconds()) * 1000)
    return () => clearTimeout(timeout)
  }, [now])

  useEffect(() => {
    if (textareaRef.current && characterRef.current) {
      const charWidth = characterRef.current.getBoundingClientRect().width
      const lineHeight = parseInt(window.getComputedStyle(characterRef.current).lineHeight)
      const paddingLeft = parseInt(window.getComputedStyle(textareaRef.current).paddingLeft)
      const paddingRight = parseInt(window.getComputedStyle(textareaRef.current).paddingRight)
      const innerWidth = textareaRef.current.clientWidth - paddingLeft - paddingRight
      setCharactersPerLine(Math.floor(innerWidth / charWidth))
      setLineHeight(lineHeight)
    }
  }, [resizeRect])

  const softLineBreaks = parseSoftLineBreaks(val, charactersPerLine)

  const times = parseTimeLines(val, now)

  const totalMinutes = times
    .filter((tl) => !tl.isGroupSummary)
    .map((t) => t.minutes)
    .reduce(sum, 0)

  const totalRoundedMinutes = times
    .filter((tl) => tl.isGroupSummary)
    .map((t) => roundUpToClosest(t.minutes, multiple))
    .concat(
      // round all non-grouped times together
      roundUpToClosest(
        times
          .filter((tl) => !tl.isGroupSummary && !tl.group)
          .map((t) => t.minutes)
          .reduce(sum, 0),
        multiple
      )
    )
    .reduce(sum, 0)

  function handleCopyToClipboard() {
    const formattedTimesheet = formatTimesheet(times, multiple)

    window.clearTimeout(copyTimeoutRef.current)
    if (!window.navigator.clipboard) {
      console.warn('Clipboard not available (needs https)')
      if (window.location.hostname === 'localhost') {
        setCopied(true)
        copyTimeoutRef.current = window.setTimeout(() => setCopied(false), 1000)
      }
      return
    }

    window.navigator.clipboard
      .writeText(formattedTimesheet)
      .catch((e) => console.log('Could not copy to clipboard'))
      .then(() => {
        setCopied(true)
        copyTimeoutRef.current = window.setTimeout(() => setCopied(false), 1000)
      })
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === 'd' && event.metaKey) {
      event.preventDefault()
      const input = event.currentTarget
      const formatter = new Intl.DateTimeFormat('en-au', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      const currentTime = formatter.format(new Date()).split(' ')[0]
      const isStartOfLine = input.selectionStart === 0 || input.value[input.selectionStart - 1] === '\n'
      const injectedText = isStartOfLine ? `${currentTime} - ` : `${currentTime}\n`
      // this allows us to keep the undo/redo stack
      document.execCommand('insertText', false, injectedText)
      // const newValue = input.value.slice(0, input.selectionStart) + injectedText + input.value.slice(input.selectionEnd)
      // const caretPosition = input.selectionStart + injectedText.length
      // input.value = newValue
      // input.setSelectionRange(caretPosition, caretPosition)
    }

    if (event.key === 's' && event.metaKey) {
      event.preventDefault()
      handleCopyToClipboard()
    }
  }

  // Focus the textarea when the window receives focus
  useEffect(() => {
    window.addEventListener('focus', () => {
      if (textareaRef.current) {
        textareaRef.current.focus()
      }
    })
  }, [])

  return (
    <div className="App flex">
      <div className="container flex flex-column">
        <div className="flex flex-auto" ref={resizeObserverRef}>
          <textarea
            ref={textareaRef}
            className="line line-bg width-50 flex-auto"
            value={val}
            onKeyDown={handleKeyDown}
            onChange={(e) => setVal(e.target.value)}
            placeholder={placeholder}
            autoFocus
          />
          <div className="line-bg pb-8" style={{ flex: '0 1 12rem' }}>
            {times.map((time, i) => (
              <div
                key={i}
                className={`line text-right ${time.valid ? '' : 'text-red'} ${time.isGroupSummary ? 'bold' : ''}`}
                style={{ marginTop: softLineBreaks[i] * lineHeight || undefined }}>
                {time.isGroupSummary
                  ? minutesToHours(roundUpToClosest(time.minutes, multiple)) + 'h'
                  : time.label
                  ? minutesToTime(time.minutes)
                  : `\u00A0`}
              </div>
            ))}
            <div className="line">
              <div className="flex">
                <span>
                  <span ref={characterRef}>T</span>otal:
                </span>
                <div className="spacer flex-auto" />
                <span>{minutesToTime(totalMinutes)}</span>
              </div>
            </div>
            <div className="line">
              <div className="flex">
                <span className="bold">Rounded:</span>
                <div className="spacer flex-auto" />
                <span className="bold">{minutesToHours(totalRoundedMinutes)}h</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex p-4">
          <button
            type="button"
            onClick={() => window.confirm('Are you sure you want to clear this?') && setVal('')}
            className="button-danger flex-auto"
            disabled={!val.length}>
            Clear
          </button>
          <div className="spacer" />
          <button
            type="button"
            onClick={handleCopyToClipboard}
            className="button-primary flex-auto"
            disabled={!totalMinutes}
            style={{ backgroundColor: copied ? 'greenyellow' : undefined }}>
            {copied ? '✅' : '📋'} Copy Daily Status
          </button>
        </div>
      </div>
    </div>
  )
}

const placeholder = `eg.
Job name 1
9.30-10.30
10.45 - 11.25
11.40-12.05

Job name 2
12:55-1:15
2:05-2:50
3-4
4 PM - 5:30 PM
17:00 - 18:00

Shortcuts:
- Cmd+D to add current time
- Cmd+S to copy to clipboard
`
