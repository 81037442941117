import { Time, TimeLine } from '../types'

export function parseTimeLines(input: string, now?: Date): TimeLine[] {
  return (
    input
      .split(/\n/)
      .map(parseTimeLine)
      // look at all lines to see if it is an unfinished time.
      // if so, we calculate the minutes from the start time to now.
      // but we keep the time marked as an invalid time.
      .map((tl) => {
        if (now && !tl.valid && looksLikeStartTime(tl.label)) {
          const dummyLabel = tl.label.replace(/[-\s]+$/, '') + ' - ' + (now.getHours() % 12) + ':' + now.getMinutes()
          const dummyTimeLine = parseTimeLine(dummyLabel)
          tl.minutes = dummyTimeLine.minutes
        }
        return tl
      })
      // calculate all the minutes for the group labels as a subtotal
      .map((tl, i, arr) => {
        if (tl.isGroupSummary) {
          for (let j = i + 1; j < arr.length; j++) {
            if (arr[j].isGroupSummary) {
              break
            }
            tl.minutes += arr[j].minutes
            // if any of the lines in the group are invalid but have minutes, then the group is invalid
            if (arr[j].minutes && !arr[j].valid) {
              tl.valid = false
            }
            arr[j].group = tl
          }
        }
        return tl
      })
  )
}

function parseTimeLine(s: string): TimeLine {
  s = s.trim()
  const matches = s.match(/^(\d{1,2}(?:[:.]\d\d)?)(?:\s*[apm]+)?\s*-\s*(\d{1,2}(?:[:.]\d\d)?)(?:\s*[apm]+)?$/i)
  if (!matches) {
    if (looksLikeGroupLabel(s)) {
      return { label: s, minutes: 0, valid: true, isGroupSummary: true }
    }
    return { label: s, minutes: 0, valid: false }
  }
  const startTime = parseTime(matches[1])
  const endTime = parseTime(matches[2])
  if (
    startTime.hour < 0 ||
    startTime.hour > 24 ||
    endTime.hour < 0 ||
    endTime.hour > 24 ||
    startTime.minute > 60 ||
    endTime.minute > 60
  ) {
    return { label: s, minutes: 0, valid: false }
  }
  if (endTime.hour < startTime.hour) {
    endTime.hour += 12
  }
  const minutes = (endTime.hour - startTime.hour) * 60 + (endTime.minute - startTime.minute)
  if (minutes <= 0) {
    return { label: s, minutes: 0, valid: false }
  }
  return { label: s, minutes, valid: true }
}

function parseTime(input: string): Time {
  const bits = input.split(/[:.]/)
  const hour = parseInt(bits[0], 10)
  const minute = parseInt(bits[1] || '0', 10)
  return { hour, minute }
}

function looksLikeGroupLabel(label: string): boolean {
  return !!label.match(/^[a-z]/i)
}

function looksLikeStartTime(label: string): boolean {
  return !!label.match(/^[0-9]{1,2}:[0-9]{2}\s*-?\s*$/i)
}
