import { useEffect, useState, useRef, useCallback } from 'react'

export const useLocalStorage = <T>(key: string, initialValue?: T | (() => T), raw?: boolean) => {
  return useStorage(localStorage, key, initialValue, raw)
}

export const useSessionStorage = <T>(key: string, initialValue?: T | (() => T), raw?: boolean) => {
  return useStorage(sessionStorage, key, initialValue, raw)
}

const useStorage = <T>(
  storage: Storage,
  key: string,
  initialValue?: T | (() => T),
  raw?: boolean
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const lastKeyRef = useRef('')
  const valueRef = useRef<T | undefined>(undefined)
  const [, forceRender] = useState(0)

  if (lastKeyRef.current !== key) {
    if (typeof initialValue === 'function') {
      initialValue = (initialValue as any)() as T
    }
    try {
      const storageValue = storage.getItem(key)
      if (typeof storageValue !== 'string') {
        valueRef.current = initialValue
      } else {
        valueRef.current = raw ? storageValue : JSON.parse(storageValue || 'null')
      }
    } catch (e) {
      // If user is in private mode or has storage restriction
      // storage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      // return initialValue
      valueRef.current = initialValue
    }
  }

  const state = valueRef.current as T

  useEffect(() => {
    try {
      const serializedState = raw ? String(state) : JSON.stringify(state)
      storage.setItem(key, serializedState)
    } catch (e) {
      // If user is in private mode or has storage restriction
      // storage can throw. Also JSON.stringify can throw.
    }
  }, [key, state, raw, storage])

  lastKeyRef.current = key

  const setState: React.Dispatch<React.SetStateAction<T>> = useCallback((value) => {
    if (typeof value === 'function') {
      valueRef.current = (value as (arg0: T) => T)(valueRef.current as T)
    } else {
      valueRef.current = value
    }
    forceRender((i) => i + 1)
  }, [])

  return [state, setState]
}

/*

if on initial render, or when key changes
 - find in storage, or
 - if initial is function, call it and use return value, or
 - use initial value

after render, if value or key changes, save in storage

return [state, setState]

*/
