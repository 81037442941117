export function parseSoftLineBreaks(text: string, charactersPerLine: number): number[] {
  return text.split(/\n/).map((line) => {
    if (line.length <= charactersPerLine) {
      return 0
    }
    let lineBreaks = 0
    let softBreakPosition = -1
    let numChars = 0
    const chars = line.split('')
    for (let i = 0; i < chars.length; i++) {
      const char = chars[i]
      numChars++
      if (isSoftBreakCharacter(char)) {
        softBreakPosition = i
      }
      if (numChars === charactersPerLine) {
        lineBreaks++
        numChars = 0
        if (softBreakPosition !== -1) {
          i = softBreakPosition + 1
          softBreakPosition = -1
        }
      }
    }
    return lineBreaks
  })
}

function isSoftBreakCharacter(char: string): boolean {
  return char === ' ' || char === '-' || char === '–'
}
