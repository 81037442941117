import { TimeLine } from '../types'
import { minutesToHours } from './timeUtils'
import { sum, roundUpToClosest } from './numberUtils'

export function formatTimesheet(times: TimeLine[], multiple: number): string {
  let str = `Today\n`
  str += times
    .filter((tl) => tl.isGroupSummary)
    .map((tl) => `• ${tl.label} – ${minutesToHours(roundUpToClosest(tl.minutes, multiple))}h\n`)
    .join('')
  const otherMinutes = times
    .filter((tl) => !tl.isGroupSummary && !tl.group)
    .map((tl) => tl.minutes)
    .reduce(sum, 0)
  if (otherMinutes > 0) {
    str += `• Other – ${minutesToHours(roundUpToClosest(otherMinutes, multiple))}h\n`
  }
  return str
}
