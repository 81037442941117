import React from 'react'
import { createRoot } from 'react-dom/client'
import './reset.css'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const rootEl = document.getElementById('root')
if (!rootEl) throw new Error('No root element found')
const root = createRoot(rootEl)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register()
